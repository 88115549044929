
  .nav-menu {
    background: #00000022;
    color: #fb4503;
    padding: 1%;;
    width: 100%;
    z-index: 900;
  }
  
  .nav-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .logo {
    font-size: 2rem;
    font-weight: bold;
  }
  
  .menu-toggle {
    font-size: 1.5rem;
    cursor: pointer;
    display: none;
  }
  
  /* Nav Links */
  .nav-links {
    display: flex;
    list-style: none;
    gap: 15px;
  }
  
  .nav-links li {
    position: relative;
  }
  
  .nav-links a {
    color: #fff;
    text-decoration: none;
    padding: 8px 15px;
    font-size: 1.5rem;
    display: inline-block;
    transition: background 0.3s;
  }
  
  .nav-links a:hover {
    background: #005f99;
    border-radius: 5px;
  }
  
  /* Dropdown Menu */
  .dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background: #005f99;
    list-style: none;
    padding: 10px 0;
    min-width: 200px;
    z-index: 1000;
    border-radius: 5px;
  }
  
  .dropdown-menu li {
    padding: 5px 15px;
  }
  
  .dropdown-menu a {
    padding: 5px 0;
  }
  
  .dropdown-menu a:hover {
    background: #007acc;
  }
  
  /* Show dropdown */
  .dropdown-menu.show {
    display: block;
  }
  
  /* Mobile Menu */
  .nav-links.open {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 60px;
    left: 0;
    background: #004d80;
    width: 100%;
    height: 100vh;
    padding-top: 20px;
  }
  
  .nav-links li {
    text-align: center;
  }
  
  .menu-toggle {
    display: block;
  }
  
  /* Media Queries */
  @media (min-width: 768px) {
    .menu-toggle {
      display: none;
    }
    
    .nav-links {
      display: flex;
      flex-direction: row;
      position: static;
      height: auto;
    }
    
    .nav-links.open {
      display: flex;
    }
  
    .dropdown-menu {
      position: absolute;
    }
  }
  