/* General Styles */
.footer {
    background-color: #000000; /* Match the color scheme of the nav menu */
    color: #fff;
    text-align: center;
    padding: 15px 10px;
    position: fixed;
    bottom: 0;
    width: 100%;
    font-size: 0.9rem;
    z-index: 1000;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .footer-content p {
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  @media (min-width: 768px) {
    .footer {
      font-size: 1rem;
    }
  }
  