body{
  min-height: 100%; 
  background-color: #1d1d1df1;
  background-repeat: no-repeat;
  background-size: cover;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* General styles for larger screens */
.searchText{
  font-size: 18px;
  margin-top: 4%;
}
.headerText {
  text-align: center;
  color: #ffffff;
}
.miladText {
  color: #ffffff;
  text-align: center;
  line-height: 0.01%;
}

/* Responsive styles for mobile devices */
@media (max-width: 768px) {
  .searchText{
    margin-top: 37%;
  }
  .headerText {
    font-size: 22px; /* Reduce the font size for smaller screens */
  }
  .miladText {
    font-size: 14px; /* Slightly smaller font size */
  }
}
@media (max-width: 480px) {
  .searchText{
    margin-top: 37%;;
  }
  .headerText {
    font-size: 20px; /* Further reduce font size for very small screens */
  }
  .miladText {
    font-size: 12px; /* Even smaller font size for tiny screens */
  }
}
@media screen and (orientation: portrait) {
  @-ms-viewport {
      width: 768px;
      height: 1024px;
   }
   body{
    min-height: 100%; 
    background-color: #282c34;
    background-image: url(./images/bg4.png);
    background-repeat: none;
    background-size: cover;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

