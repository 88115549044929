@import url(//fonts.googleapis.com/css?family=Lato:400,900|Open+Sans:400,300,800|Raleway:400,300,800|Old+Standard+TT:400,400italic,700);

html {
    font-size: 62.5%;
}

*,*::before,*::after {
  margin: 0;
  padding: 0;
  line-height: inherit;
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: auto;
  line-height: 2;
  font-family: IRANSansX  !important;
	direction: rtl;
	margin: 0;
}
.searchBox{
    width: 380px;
    height: auto;
    background-color: #fbffff;
  /*   margin: 20vh auto; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4rem;
    padding: 10px;
   
}

.search_input {
    float: left;
    width: 0;
    height: 4.2rem;
    background: none;
    color: #f2540b;
    font-size: 1.6rem;
    border-radius: 2rem;
    outline: none;
    border: none;
    position: relative;
    opacity: 1;
    transition: all .75s ease-in;
    cursor: pointer;
    text-align: center;
  }
  
.search__icon {
    box-sizing: border-box;
    float: right;
    font-size: 2.5rem;
    display: inline-block;
  /*   justify-content: center;
    align-items: center; */
    margin-left: .8rem;
    margin-top: 0;
    cursor: pointer;
    position: absolute;
    color: #f2540b;
    transition: all .25s ease-in;
    padding: .7rem;
    border-radius: 50%;
  }
  
  .searchBox:hover > .search_input {
    width: 85%;
    padding: 0 1rem;
  }
  
  .searchBox:hover > .search__icon {
    background-color: #eee;
  }

  .show {
    width: 85%;
    border: 1px solid red;
  }
  /* Styling for the input field on hover */
.search_input.show {
  border-color: #ff0000;
  transition: border-color 0.3s ease;
}

/* Popup Styling */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: scroll;
}

.popup-content {
  background: white;
  padding: 35px;
  border-radius: 8px;
  max-width: 750px;
  width: 95%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  font-size: 16px;
}

.close-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  font-size: 45px;
  cursor: pointer;
}
.printButton{
  font-size: 16px;
  padding:2px;
  width: 90px;
  margin-left: 5px;
  background-color: rgb(72, 109, 241);
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  border-radius: 5px ;
}
.second_closeButton{
  font-size: 16px;
  padding:2px;
  width: 90px;
  margin-left: 5px;
  background-color: rgb(252, 11, 11);
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  border-radius: 5px ;
}

/* error popup */
/* Popup Styling */
.Errorpopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.Errorpopup-content {
  background: white;
  padding: 35px;
  border-radius: 8px;
  max-width: 750px;
  max-height: 250px;;
  width: 95%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  font-size: 16px;
}
.Errorpopup-content p{
  font-size: 23px;
}
.Error_popup-buttons{
  position: relative;
  padding: 2px;
}
.Error_close-btn {
  width: 65px;
  margin: 10px;;
  background-color: rgb(252, 11, 11);
  border: none;
  font-size: 25px;
  cursor: pointer;
}